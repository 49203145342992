class FormItem {
  field = "";
  defaultValue = "";
  translationField = "";
  inputType = "";
  required = false;

  //Optional config
  selectOptions = [];
  icon = null;
  autoFocus = false;

  constructor(
    field,
    inputType,
    required,
    {
      selectOptions,
      translationField,
      icon,
      autoFocus,
      isToggled,
      validator,
    } = {}
  ) {
    this.field = field;
    this.translationField = translationField ?? field;
    this.inputType = inputType;
    this.required = required;

    this.selectOptions = selectOptions;
    this.icon = icon;
    this.autoFocus = autoFocus;
    this.isToggled = isToggled;
    this.validator = validator || defaultValidator;
    if (this.field === "wantsToConnect") {
      this.defaultValue = true;
    }
    let urlDecisionField = window.location.search.split("&").filter((c) => c.indexOf('decision') !== -1);
    let urlDecisionValue = urlDecisionField.length > 0 ? urlDecisionField[0].split('=')[1] : '';
    if(this.field === "acceptedChrist" && parseInt(urlDecisionValue)==1){
      this.defaultValue = 'Yes';
    }
  }
}

const defaultValidator = (value) => !!value;
export default FormItem;
