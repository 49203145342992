import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLanguage from "../../hooks/use-language";
import useTranslations from "../../hooks/use-translations";
import TranslationService from "../../services/translation-service";
import Form from "../form";

const FullPageForm = ({
  additionalFormData = {},
  alwaysShowToggledFields = false,
  translationKey,
  formType,
  logo,
  logoWidth,
  colorScheme = null,
  ...props
}) => {
  const language = useLanguage();
  const translations = useTranslations(language, translationKey);
  if (colorScheme) {
    //Need a better solution if we need to expand color scheme functionality
    document.getElementById("root").setAttribute("color-scheme", colorScheme);
  }

  return (
    <div className="page-form">
      {logo && logoWidth ? (
        <img src={logo} className="logo" alt="logo" width={logoWidth} />
      ) : logo ? (
        <img src={logo} className="logo" alt="logo" width="240px" />
      ) : (
        <div />
      )}
      <Form
        translations={translations}
        additionalFormData={{
          ...additionalFormData,
          language,
          form_type: formType,
        }}
        alwaysShowToggledFields={alwaysShowToggledFields}
        {...props}
      />
      <div className="language">
        <FontAwesomeIcon icon={faGlobe} />
        {TranslationService.getLanguage(language)?.label}
      </div>
    </div>
  );
};

export default FullPageForm;
